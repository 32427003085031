<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irrigation_config.water_testing_param') }} </h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('org_pro.organization')"
                label-for="org_id"
                >
                    <b-form-select
                        plain
                        v-model="search.org_id"
                        :options="orgList"
                        id="org_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col  lg="6" md="6" sm="12" xs="12">
              <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('irrigation_config.water_testing_param') + ' ' + $t('pump_install.name')"
                label-for="scheme"
              >
                <b-form-input
                  id="scheme"
                  v-model="search.name"
                  :placeholder="$t('irrigation_config.water_testing_param') + ' ' + $t('pump_install.name')"
                  ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('irrigation_config.testing_type_id')"
                label-for="id"
                >
                    <b-form-select
                        plain
                        v-model="search.testing_type_id"
                        :options="testingTypes"
                        id="id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
            </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irrigation_config.water_testing_param') + ' ' + $t('globalTrans.list')}} </h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(testing_type_id)="data">
                      {{ getTestingType(data.item.testing_type_id) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <!-- {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }} -->
                       <span class="badge badge-danger" v-if="data.item.status">{{ $t('globalTrans.inactive') }}</span>
                        <span class="badge badge-success" v-else>{{ $t('globalTrans.active') }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_status" title="Active" v-if="data.item.status === 0" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Inactive" v-else @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>
import FormV from './Form'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { waterTestParamList, waterTestParamToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import { core } from '@/config/pluginInit'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        FormV
    },
    data () {
        return {
            pagination: {
              currentPage: 1,
              totalRows: 0,
              perPage: this.$store.state.commonObj.perPage,
              slOffset: 1
            },
            search: {
                org_id: 0,
                testing_type_id: 0,
                name: ''
            },
            waterTestParamList: [],
            rows: [],
            testingTypes: [
              { value: 1, text: 'Drinking Water' },
              { value: 2, text: 'Irrigation Water' },
              { value: 3, text: 'Industrial Water' }
            ]
        }
    },
    computed: {
      ...mapGetters({
      authUser: 'Auth/authUser'
      }),
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('globalTrans.add_new') : this.$t('globalTrans.edit')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('globalTrans.organization'), class: 'text-center' },
                { label: this.$t('pump_install.name'), class: 'text-center' },
                { label: this.$t('irrigation_config.testing_type_id'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
                ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'org_name_bn' },
                { key: 'name_bn' },
                { key: 'testing_type_id' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'org_name' },
                { key: 'name' },
                { key: 'testing_type_id' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    watch: {
        loadingState: function (newVal, oldVal) {
          if (newVal) {
              this.loadData()
          }
        }
    },
    mounted () {
      core.index()
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id
        })
      }
      this.loadData()
    },
    methods: {
        async searchData () {
          if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
            this.search = Object.assign({}, this.search, {
              org_id: this.authUser.org_id
            })
          } else if (this.authUser.role_id === 0) {
            this.search = Object.assign({}, this.search, {
              org_id: this.authUser.office_detail.org_id
            })
          }
          this.loadData()
        },
        remove (item) {
          this.changeStatus(irriSchemeServiceBaseUrl, waterTestParamToggleStatus, item, 'common', 'waterTestParamList')
        },
        loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(irriSchemeServiceBaseUrl, waterTestParamList, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', this.dataList(response.data.data))
                  this.paginationData(response.data)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
        },
        dataList (data) {
            const orgList = this.$store.state.orgList
            let tmpData = {}
            const listData = data.map(item => {
                tmpData = orgList.find(orgItem => orgItem.value === item.org_id & orgItem.status === 0)
                const orgData = { org_name: tmpData.text_en, org_name_bn: tmpData.text_bn }
                return Object.assign({}, item, orgData)
            })
            return listData
        },
        getTestingType (type) {
          if (type === 1) {
            return this.$i18n.locale === 'bn' ? 'খাবার পানি' : 'Drinking Water'
          } else if (type === 2) {
            return this.$i18n.locale === 'bn' ? 'সেচের পানি' : 'Irrigation Water'
          } else if (type === 3) {
            return this.$i18n.locale === 'bn' ? 'শিল্প কারখানার পানি' : 'Industrial Water'
          }
        }
    }
}
</script>
