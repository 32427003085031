<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col md="12" sm="12">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form  @submit.prevent="handleSubmit(storeUpdateData)" @reset.prevent="reset" >
                                        <ValidationProvider name="Organization" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="org_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="waterTestingParam.org_id"
                                                :options="orgList"
                                                id="org_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="Name (En)" vid="name" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="office_type_name"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('irrigation_config.name') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="name"
                                                v-model="waterTestingParam.name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="Name (Bn)" vid="name_bn" >
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="name_bn-bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('irrigation_config.name_bn')}}
                                                </template>
                                                <b-form-input
                                                id="namebn"
                                                v-model="waterTestingParam.name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="Testing Type" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="testing_type_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('irrigation_config.testing_type_id')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="waterTestingParam.testing_type_id"
                                                :options="testingTypes"
                                                id="org_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                &nbsp;
                                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                            </div>
                                        </div>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { waterTestParamStore, waterTestParamUpdate } from '../../api/routes'

export default {
    name: 'FormLayout',
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getWaterTestingParamData()
            this.waterTestingParam = tmp
        }
    },
    mounted () {
        core.index()
    },
    data () {
        return {
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            waterTestingParam: {
                org_id: 0,
                testing_type_id: 0,
                name: '',
                name_bn: ''
            },
            testingTypes: [
              { value: 1, text: 'Drinking Water' },
              { value: 2, text: 'Irrigation Water' },
              { value: 3, text: 'Industrial Water' }
            ]
        }
    },
    computed: {
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList
        }
    },
    methods: {
        getWaterTestingParamData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async storeUpdateData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(irriSchemeServiceBaseUrl, `${waterTestParamUpdate}/${this.id}`, this.waterTestingParam)
            } else {
                result = await RestApi.postData(irriSchemeServiceBaseUrl, waterTestParamStore, this.waterTestingParam)
            }

            loadingState.listReload = true

            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.dispatch('IrriConfig/mutateCommonObj', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
}
</script>
